import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';

import { SnackbarProvider } from 'notistack';

import Web3ManageProvider from 'providers/Web3ManageProvider';
import store from 'redux/store';
import { setLoading } from 'redux/actions';
import { CreateSocket } from './SocketIO';
import ErrorHandling from 'components/Gunbot/ErrorHandling';
import App from './App.js';

import 'assets/css/themes.css';
import 'index.css';
import StepGuideProvider from 'providers/StepGuideProvider';
import ConfirmDialogProvider from 'providers/ConfirmDialogProvider';
import StartupGunbotSetupProvider from 'views/StartupGunbotSetup/StartupGunbotSetupProvider';

function getLibrary(provider) {
  return new Web3Provider(provider);
}

Number.prototype.toFixedDown = function (digits) {
  const re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)'),
    m = this.toString().match(re);
  return m ? parseFloat(m[1]) : this.valueOf();
};

React.Component.prototype.generateState = function (data = []) {
  const state = {};
  data.forEach(item => {
    if (item.children) {
      state[item.name] = this.generateState(item.children);
    } else {
      state[item.name] = item.value;
    }
  });
  return state;
};

React.Component.prototype.setValue = function (value, key, ...keys) {
  keys = keys.reverse();
  let data = this.state;
  for (let i = 0; i < keys.length; i++) {
    if (keys[i] != null) {
      if (data[keys[i]] === undefined) data[keys[i]] = {};
      data = data[keys[i]];
    }
  }
  data[key] = value;
  this.setState(this.state);
};

React.Component.prototype.reGetValue = function (key, ...parents) {
  parents = parents.reverse();
  let data = this.state;
  parents.forEach(key => {
    if (key != null) {
      if (data[key] === undefined) {
        data[key] = {};
      }
      data = data[key];
    }
  });
  return data[key];
};

React.Component.prototype.getValue = function (key, ...parents) {
  parents = parents.reverse();
  let data = this.state;
  let isUndefined = false;
  parents.forEach(key => {
    if (key != null) {
      if (data[key] === undefined) {
        data[key] = {};
        isUndefined = true;
      }
      data = data[key];
    }
  });

  if (isUndefined || data[key] === undefined) {
    this.state = this.generateState(this.props.data);
    return this.reGetValue(key, ...parents);
  }

  return data[key];
};

React.Component.prototype.loading = function (loading) {
  store.dispatch(setLoading(loading));
};

export const themeColors = {
  primary: '#f0b90b',
  secondary: '#B71C1C',
  success: 'rgb(86, 202, 0)',
  error: 'rgb(255, 62, 29)',
  info: 'rgb(22, 177, 255)',
};

const theme = createTheme({
  palette: {
    type: 'dark',
    tertiary: {
      main: '#222222',
    },
    orders: {
      buy: '#56ca00',
      sell: '#f13c1d',
    },
    primary: {
      main: themeColors.primary,
    },
    secondary: {
      main: themeColors.secondary,
    },
    background: {
      default: '#292929',
      paper: '#222222',
    },
    success: {
      main: themeColors.success,
    },
    error: {
      main: themeColors.error,
    },
    info: {
      main: themeColors.info,
    },
    text: {
      primary: '#ddd',
    },
  },
  spacing: factor => `${0.5 * factor}rem`,
  typography: {
    body2: {
      color: '#ddd',
    },
    subtitle2: {
      textTransform: 'none',
      transition: '.3s',
      color: 'rgba(231, 227, 252, 0.5)',
    },
    caption: {
      textTransform: 'none',
      transition: '.3s',
      color: 'rgba(231, 227, 252, 0.5)',
    },
  },
  overrides: {
    MuiTabs: {
      root: {
        float: 'left',
      },
    },
    MuiAlert: {
      icon: {
        display: 'none',
      },
    },
    MuiPaper: {
      root: {
        backgroundImage: 'none',
        borderRadius: 6,
        boxShadow: '0 10px 30px 1px rgb(0 0 0 / 6%)',
      },
    },
    MuiCssBaseline: {
      '@global': {
        color: 'red',
      },
    },
    MuiTableCell: {
      root: {
        padding: 14,
      },
    },
  },
});

ReactDOM.render(
  <ErrorHandling>
    <CreateSocket>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ConfirmDialogProvider>
            <SnackbarProvider
              preventDuplicate
              hideIconVariant
              options={{
                autoHideDuration: 7000,
              }}
              dense
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Web3ReactProvider getLibrary={getLibrary}>
                <Web3ManageProvider>
                  <StepGuideProvider>
                    <StartupGunbotSetupProvider>
                      <App />
                    </StartupGunbotSetupProvider>
                  </StepGuideProvider>
                </Web3ManageProvider>
              </Web3ReactProvider>
            </SnackbarProvider>
          </ConfirmDialogProvider>
        </ThemeProvider>
      </Provider>
    </CreateSocket>
  </ErrorHandling>,
  document.getElementById('root'),
);

